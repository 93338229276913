import React from "react"
import '../../index.scss';
import Header from "../../components/header-ja";
import Footer from "../../components/footer-ja";
import PageMetaDefault from "../../components/page-meta-default";
import { formDataFromJson } from "../../constants";

const API_STATUSES = {
  INITIAL: 'INITIAL',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  PROGRESS: 'PROGRESS',
};

class JapaneseContactUs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      desc: "",
      apiStatus: API_STATUSES.INITIAL,
      isClient: false,
    };
  }

  updateValue = (field, value) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { apiStatus } = this.state;
    if(apiStatus === API_STATUSES.PROGRESS) {
      return;
    }
    this.setState({
      apiStatus: API_STATUSES.PROGRESS,
    });
    const { name, email, desc } = this.state;
    fetch('https://formspree.io/f/xrgrlpnj', {
      method: 'post',
      headers: {
        'Accept': 'application/json'
      },
      body: formDataFromJson({
        name,
        email,
        desc
      })
    }).then((response) => {
      if (response.status < 400) {
        this.setState({
          apiStatus: API_STATUSES.SUCCESS,
        });
      } else {
        throw new Error('Something went wrong');
      }
    })
      .catch((error) => {
        this.setState({
          apiStatus: API_STATUSES.ERROR,
        });
        console.log(error)
      });
  }

  componentDidMount() {
    this.setState({
      isClient: true,
    });
  }

  render = () => {
    const {
      // name, email, desc, apiStatus,
      isClient } = this.state;
    return (
      <>
        <PageMetaDefault pageTitle="Contact Us" />
        <Header />
        <div className="width-limit">
          <div id="contact-page-wrapper" className="flex">
            {/* <div id="contact-form-div">
              <h1>お問い合わせ</h1>
              <form onSubmit={this.onSubmit} id="contact-form">
                <div>
                  <label>
                    氏名
                    <input type="text" value={name} onChange={(e) => this.updateValue('name', e.target.value)} />
                  </label>
                </div>
                <div>
                  <label>
                    メールアドレス
                    <input type="email" value={email} onChange={(e) => this.updateValue('email', e.target.value)} />
                  </label>
                </div>
                <div>
                  <label>
                    お問い合わせ内容
                    <textarea value={desc} onChange={(e) => this.updateValue('desc', e.target.value)} />
                  </label>
                </div>
                <button type="submit" className="landing-cta">送信</button>
                {
                  apiStatus === API_STATUSES.SUCCESS && (
                    <p id="contact-form-status" className="success">お問い合わせいただきありがとうございます。 折り返し連絡させていただきます。</p>
                  )
                }
                {
                  apiStatus === API_STATUSES.ERROR && (
                    <p id="contact-form-status" className="form-error">エラーが発生しました。 もう一度お試しください</p>
                  )
                }
                {
                  apiStatus === API_STATUSES.PROGRESS && (
                    <p id="contact-form-status">リクエストを送信しています。しばらく お待ちください...</p>
                  )
                }
              </form>
            </div> */}
            <div id="contact-address">
              <h1>ロケーション</h1>
              {
                isClient && (
                  <iframe
                    title="株式会社ユークリッドXRイマーシブ住所"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.407403482588!2d139.68926971525835!3d35.66696928019775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d508fe834f1%3A0x3e5a23aa00a7b6f8!2z5qCq5byP5Lya56S-44Om44O844Kv44Oq44OD44OJWFLjgqTjg57jg7zjgrfjg5Y!5e0!3m2!1sen!2sin!4v1631516883547!5m2!1sen!2sin"
                    style={{border: 0, width: "100%", height: "377px" }} allowFullScreen="" loading="lazy"
                  />
                )
              }
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default JapaneseContactUs;
